.anchor-target {
  display: block;
  position: relative;
  visibility: hidden;
}

.last-child-margin-reset > :last-child {
  margin-bottom: 0;
}

p {
  line-height: 1.39;
}
li {
  line-height: 1.39;
}

ol {
  margin-left: 1rem;
  counter-reset: item;
  list-style: none outside none;
}
ol > li:before {
  content: counter(item) ') ';
  counter-increment: item;
  position: absolute;
  left: -2rem;
}

ol > li {
  position: relative;
}

button:focus {
  outline: none;
}

/* root element of the app */
#__next {
  position: relative;
  display: flex;
}

/* Do not display section divider in the first section */
/* mb-section-top-mobile is the section divider class name taken from src/contentTypes/organisms/Section/index.tsx */
section:first-of-type hr.mb-section-top-mobile {
  display: none;
}

section:last-of-type {
  margin-bottom: 4rem;
}

.research-css-arrow::after {
  border-color: transparent;
  border-style: solid;
  content: '';
  height: 0;
  position: absolute;
  pointer-events: none;
  top: 15px;
  width: 0;
  left: -10px;
  border-right-color: #feece0;
  border-width: 9px 10px 9px 0;
}

@media (max-width: 1024px) {
  .research-css-arrow::after {
    border-width: 0 9px 10px 9px;
    border-bottom-color: #feece0;
    border-right-color: transparent;
    top: -10px;
    left: 11px;
  }
}

.ecoupon-css-arrow::after {
  border-color: transparent;
  border-style: solid;
  content: '';
  height: 0;
  position: absolute;
  pointer-events: none;
  top: 15px;
  width: 0;
  left: -10px;
  border-right-color: #f3f6fe;
  border-width: 9px 10px 9px 0;
}

@media (max-width: 1024px) {
  .ecoupon-css-arrow::after {
    border-width: 0 9px 10px 9px;
    border-bottom-color: #f3f6fe;
    border-right-color: transparent;
    top: -10px;
    left: 11px;
  }
}

.disclosure-link-drawer {
  animation: slide 0.4s ease;
}

.quote-card-ldquo {
  font-size: 144px;
  line-height: 1;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .quote-card-ldquo {
    font-size: 96px;
    top: 10px;
  }
}

@media (max-width: 768px) {
  .disclosure-link-drawer {
    animation: slide-mobile 0.4s ease;
  }
}

.focus-outline:focus {
  border: 1px solid #5599ff;
}

.input-icon-position-y {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.affirm-modal-trigger {
  color: #0f56a6;
  text-decoration: underline;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-mobile {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.smooth-scroll {
  -webkit-overflow-scrolling: touch;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* Workaround for IE11 incompatible usages of flex-1 */
  .ie11-flex-auto {
    flex-basis: auto;
  }

  /* Workaround for IE11 flexbug where text does not wrap */
  .ie11-flex-wrap {
    overflow-x: auto;
  }
}

@supports (padding: max(0px)) {
  .fullScreenSafeArea {
    padding-top: max(0px, env(safe-area-inset-top));
  }
}

.cms-editormode:hover {
  outline: 3px solid #0f56a6;
  outline-offset: 4px;
  cursor: pointer;
}

@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/regular.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
  unicode-range: U+000D, U+0020-007E, U+00A0-0107, U+010A-0113, U+0116-011B, U+011E-0127, U+012A-012B, U+012E-0148,
    U+014A-014D, U+0150-015B, U+015E-0167, U+016A-017E, U+0192, U+0218-021B, U+0237, U+02C6-02C7, U+02D8-02DD,
    U+0300-0304, U+0306-0308, U+030A-030C, U+0312, U+0326-0328, U+0394, U+03A9, U+03BC, U+03C0, U+1E80-1E85, U+1E9E,
    U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2044,
    U+2053, U+2070, U+2074-2079, U+2080-2089, U+20AC, U+20BA, U+20BD, U+20BF, U+2116-2117, U+2122, U+215B-215E,
    U+2190-2199, U+2205, U+2212, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+2460-2468, U+24EA, U+24FF,
    U+25CA, U+2776-277E, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/medium.woff2') format('woff2');
  font-weight: 500;
  font-weight: medium;
  font-style: normal;
  unicode-range: U+000D, U+0020-007E, U+00A0-0107, U+010A-0113, U+0116-011B, U+011E-0127, U+012A-012B, U+012E-0148,
    U+014A-014D, U+0150-015B, U+015E-0167, U+016A-017E, U+0192, U+0218-021B, U+0237, U+02C6-02C7, U+02D8-02DD,
    U+0300-0304, U+0306-0308, U+030A-030C, U+0312, U+0326-0328, U+0394, U+03A9, U+03BC, U+03C0, U+1E80-1E85, U+1E9E,
    U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2044,
    U+2053, U+2070, U+2074-2079, U+2080-2089, U+20AC, U+20BA, U+20BD, U+20BF, U+2116-2117, U+2122, U+215B-215E,
    U+2190-2199, U+2205, U+2212, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+2460-2468, U+24EA, U+24FF,
    U+25CA, U+2776-277E, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/bold.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: normal;
  unicode-range: U+000D, U+0020-007E, U+00A0-0107, U+010A-0113, U+0116-011B, U+011E-0127, U+012A-012B, U+012E-0148,
    U+014A-014D, U+0150-015B, U+015E-0167, U+016A-017E, U+0192, U+0218-021B, U+0237, U+02C6-02C7, U+02D8-02DD,
    U+0300-0304, U+0306-0308, U+030A-030C, U+0312, U+0326-0328, U+0394, U+03A9, U+03BC, U+03C0, U+1E80-1E85, U+1E9E,
    U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2044,
    U+2053, U+2070, U+2074-2079, U+2080-2089, U+20AC, U+20BA, U+20BD, U+20BF, U+2116-2117, U+2122, U+215B-215E,
    U+2190-2199, U+2205, U+2212, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+2460-2468, U+24EA, U+24FF,
    U+25CA, U+2776-277E, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/regularItalic.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: italic;
  unicode-range: U+000D, U+0020-007E, U+00A0-0107, U+010A-0113, U+0116-011B, U+011E-0127, U+012A-012B, U+012E-0148,
    U+014A-014D, U+0150-015B, U+015E-0167, U+016A-017E, U+0192, U+0218-021B, U+0237, U+02C6-02C7, U+02D8-02DD,
    U+0300-0304, U+0306-0308, U+030A-030C, U+0312, U+0326-0328, U+0394, U+03A9, U+03BC, U+03C0, U+1E80-1E85, U+1E9E,
    U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2044,
    U+2053, U+2070, U+2074-2079, U+2080-2089, U+20AC, U+20BA, U+20BD, U+20BF, U+2116-2117, U+2122, U+215B-215E,
    U+2190-2199, U+2205, U+2212, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+2460-2468, U+24EA, U+24FF,
    U+25CA, U+2776-277E, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/mediumItalic.woff2') format('woff2');
  font-weight: 500;
  font-weight: medium;
  font-style: italic;
  unicode-range: U+000D, U+0020-007E, U+00A0-0107, U+010A-0113, U+0116-011B, U+011E-0127, U+012A-012B, U+012E-0148,
    U+014A-014D, U+0150-015B, U+015E-0167, U+016A-017E, U+0192, U+0218-021B, U+0237, U+02C6-02C7, U+02D8-02DD,
    U+0300-0304, U+0306-0308, U+030A-030C, U+0312, U+0326-0328, U+0394, U+03A9, U+03BC, U+03C0, U+1E80-1E85, U+1E9E,
    U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2044,
    U+2053, U+2070, U+2074-2079, U+2080-2089, U+20AC, U+20BA, U+20BD, U+20BF, U+2116-2117, U+2122, U+215B-215E,
    U+2190-2199, U+2205, U+2212, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+2460-2468, U+24EA, U+24FF,
    U+25CA, U+2776-277E, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Bolton';
  src: url('../static/fonts/bolton/WOFF2/boldItalic.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: italic;
  unicode-range: U+000D, U+0020-007E, U+00A0-0107, U+010A-0113, U+0116-011B, U+011E-0127, U+012A-012B, U+012E-0148,
    U+014A-014D, U+0150-015B, U+015E-0167, U+016A-017E, U+0192, U+0218-021B, U+0237, U+02C6-02C7, U+02D8-02DD,
    U+0300-0304, U+0306-0308, U+030A-030C, U+0312, U+0326-0328, U+0394, U+03A9, U+03BC, U+03C0, U+1E80-1E85, U+1E9E,
    U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030, U+2032-2033, U+2039-203A, U+2044,
    U+2053, U+2070, U+2074-2079, U+2080-2089, U+20AC, U+20BA, U+20BD, U+20BF, U+2116-2117, U+2122, U+215B-215E,
    U+2190-2199, U+2205, U+2212, U+221A, U+221E, U+222B, U+2248, U+2260, U+2264-2265, U+2460-2468, U+24EA, U+24FF,
    U+25CA, U+2776-277E, U+F8FF, U+FB01-FB02;
}

@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/regular.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
  unicode-range: U+20- U+7E, U+A0- U+FF, U+131, U+13F- U+142, U+152- U+153, U+160- U+161, U+178, U+17D- U+17E, U+192,
    U+237, U+2C6- U+2C7, U+2D8- U+2DA, U+2DC- U+2DD, U+300- U+304, U+306- U+308, U+30A- U+30C, U+327, U+335,
    U+337- U+338, U+2000- U+200B, U+2013- U+2014, U+2018- U+201A, U+201C- U+201E, U+2020- U+2022, U+2026, U+202F- U+2030,
    U+2039- U+203A, U+2044, U+205F, U+2070, U+2074, U+20AC, U+2122, U+2212, U+F8FF, U+FB01- U+FB02;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/demi.woff2') format('woff2');
  font-weight: 500;
  font-weight: semi-bold;
  font-style: normal;
  unicode-range: U+0020-007E, U+00A0-00FF, U+0131, U+013F-0142, U+0152-0153, U+0160-0161, U+0178, U+017D-017E, U+0192,
    U+0237, U+02C6-02C7, U+02D8-02DA, U+02DC-02DD, U+0300-0304, U+0306-0308, U+030A-030C, U+0327, U+0335, U+0337-0338,
    U+2000-200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+202F-2030, U+2039-203A, U+2044, U+205F,
    U+2070, U+2074, U+20AC, U+2122, U+2212, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/bold.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: normal;
  unicode-range: U+0020-007E, U+00A0-00FF, U+0131, U+013F-0142, U+0152-0153, U+0160-0161, U+0178, U+017D-017E, U+0192,
    U+0237, U+02C6-02C7, U+02D8-02DA, U+02DC-02DD, U+0300-0304, U+0306-0308, U+030A-030C, U+0327, U+0335, U+0337-0338,
    U+2000-200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+202F-2030, U+2039-203A, U+2044, U+205F,
    U+2070, U+2074, U+20AC, U+2122, U+2212, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/regularItalic.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: italic;
  unicode-range: U+0020-007E, U+00A0-00FF, U+0131, U+013F-0142, U+0152-0153, U+0160-0161, U+0178, U+017D-017E, U+0192,
    U+0237, U+02C6-02C7, U+02D8-02DA, U+02DC-02DD, U+0300-0304, U+0306-0308, U+030A-030C, U+0327, U+0335, U+0337-0338,
    U+2000-200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+202F-2030, U+2039-203A, U+2044, U+205F,
    U+2070, U+2074, U+20AC, U+2122, U+2212, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/demiItalic.woff2') format('woff2');
  font-weight: 500;
  font-weight: semi-bold;
  font-style: italic;
  unicode-range: U+0020-007E, U+00A0-00FF, U+0131, U+013F-0142, U+0152-0153, U+0160-0161, U+0178, U+017D-017E, U+0192,
    U+0237, U+02C6-02C7, U+02D8-02DA, U+02DC-02DD, U+0300-0304, U+0306-0308, U+030A-030C, U+0327, U+0335, U+0337-0338,
    U+2000-200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+202F-2030, U+2039-203A, U+2044, U+205F,
    U+2070, U+2074, U+20AC, U+2122, U+2212, U+F8FF, U+FB01-FB02;
}
@font-face {
  font-family: 'GoodRx Moon';
  src: url('../static/fonts/moon/WOFF2/boldItalic.woff2') format('woff2');
  font-weight: 700;
  font-weight: bold;
  font-style: italic;
  unicode-range: U+0020-007E, U+00A0-00FF, U+0131, U+013F-0142, U+0152-0153, U+0160-0161, U+0178, U+017D-017E, U+0192,
    U+0237, U+02C6-02C7, U+02D8-02DA, U+02DC-02DD, U+0300-0304, U+0306-0308, U+030A-030C, U+0327, U+0335, U+0337-0338,
    U+2000-200B, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+202F-2030, U+2039-203A, U+2044, U+205F,
    U+2070, U+2074, U+20AC, U+2122, U+2212, U+F8FF, U+FB01-FB02;
}

@font-face {
  font-family: 'GoodRx Goodall';
  src: url('../static/fonts/goodall/WOFF2/regular.woff2') format('woff2');
  font-weight: 400;
  font-weight: normal;
  font-style: normal;
  unicode-range: U+000D, U+0020-007E, U+00A0-0148, U+014A-017E, U+0192, U+01B5-01B6, U+01FC-01FD, U+0218-021B, U+0237,
    U+02C6-02C7, U+02C9, U+02D8-02DD, U+0300-0304, U+0306-0308, U+030A-030C, U+0312, U+0326-0328, U+0394, U+03A9, U+03BC,
    U+03C0, U+1E80-1E85, U+1E9E, U+1EF2-1EF3, U+2013-2014, U+2018-201A, U+201C-201E, U+2020-2022, U+2026, U+2030,
    U+2039-203A, U+2044, U+2070, U+2074-2079, U+2080-2089, U+20AC, U+20BA, U+2113, U+2117, U+2120, U+2122, U+2126,
    U+212E, U+2153-2154, U+215B-215E, U+2190-2199, U+2202, U+2206, U+220F, U+2211-2212, U+2215, U+2219-221A, U+221E,
    U+222B, U+2248, U+2260, U+2264-2265, U+25CA, U+F8FF, U+FB01-FB02;
}
