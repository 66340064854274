@font-face {
  font-family: 'Matter';
  src: url('../static/fonts/matter/WEB/Matter-Regular.woff2?qmrkyf') format('woff2'),
    url('../static/fonts/matter/WEB/Matter-Regular.woff?qmrkyf') format('woff'),
    url('../static/fonts/matter/TTF/Matter-Regular.ttf?qmrkyf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('../static/fonts/matter/WEB/Matter-Medium.woff2?qmrkyf') format('woff2'),
    url('../static/fonts/matter/WEB/Matter-Medium.woff?qmrkyf') format('woff'),
    url('../static/fonts/matter/TTF/Matter-Medium.ttf?qmrkyf') format('truetype');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('../static/fonts/matter/WEB/Matter-SemiBold.woff2?qmrkyf') format('woff2'),
    url('../static/fonts/matter/WEB/Matter-SemiBold.woff?qmrkyf') format('woff'),
    url('../static/fonts/matter/TTF/Matter-SemiBold.ttf?qmrkyf') format('truetype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('../static/fonts/matter/WEB/Matter-Bold.woff2?qmrkyf') format('woff2'),
    url('../static/fonts/matter/WEB/Matter-Bold.woff?qmrkyf') format('woff'),
    url('../static/fonts/matter/TTF/Matter-Bold.ttf?qmrkyf') format('truetype');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

/* Health Sponsored pages use Tiempos for header */
@font-face {
  font-family: 'Tiempos';
  src: url(https://cdn.heydoctor.com/fonts/TiemposHeadlineWeb-Semibold.woff2) format('woff2');
}
